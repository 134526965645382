<template>
  <strong>{{ getTotal() }}</strong>
</template>

<script>
import { formatPrice } from "../../../../helpers/common";
export default {
  name: "InvoicesTableTotal",
  methods: {
    getTotal() {
      const currencySymbol = this.$store.getters["connections/currencySymbol"];
      return formatPrice(this.data.price, currencySymbol).format();
    },
  },
};
</script>
